"use client";

import { cn } from "@/lib/utils";
import React from "react";

type Props = {
  className?: string;
  imageClassName?: string;
};

const PointsOrCashSection: React.FC<Props> = ({
  className,
  imageClassName,
}) => {
  const [selectedTab, setSelectedTab] = React.useState<"points" | "cash">(
    "points"
  );
  const currentImageSrc =
    selectedTab === "points"
      ? "/assets/landing-page/points-card.svg"
      : "/assets/landing-page/cash-card.svg";

  return (
    <div className={cn("relative mb-32 sm:mb-0", className)}>
      <div className="absolute -bottom-24 -mb-24 flex w-full flex-col items-center justify-center gap-5 sm:bottom-6 sm:mb-0 sm:flex-row">
        <div
          className={cn(
            "flex w-[240px] cursor-pointer flex-col items-start justify-start gap-6 rounded-md bg-slate-950 px-6 py-4 shadow",
            selectedTab === "points"
              ? "border-2 border-primary-gold border-opacity-50"
              : "border border-white border-opacity-25"
          )}
          onClick={() => setSelectedTab("points")}
        >
          <div
            className={cn(
              "text-base font-normal leading-tight",
              selectedTab === "points" ? "text-primary-gold" : "text-primary"
            )}
          >
            With Points
          </div>
          <div className="flex flex-col items-start justify-start gap-2.5">
            <div className="text-base font-medium leading-tight text-primary">
              113,000 Points/Night
            </div>
            <div className="inline-flex items-center justify-center gap-2 rounded-[103px] bg-green-400 bg-opacity-20 px-4 py-1.5">
              <div className="inline-flex flex-col items-start justify-start gap-3">
                <div className="inline-flex items-center justify-start gap-2 rounded-[123px]">
                  <div className="relative h-2.5 w-3.5">
                    <div className="absolute left-[5.60px] top-0 h-2.5 w-[2.80px] bg-green-400" />
                    <div className="absolute left-0 top-[1.25px] h-[8.75px] w-[2.80px] bg-green-400" />
                    <div className="absolute left-[11.20px] top-[5px] h-[5px] w-[2.80px] bg-green-400" />
                  </div>
                  <div className="text-base font-normal leading-tight text-green-400">
                    9.0
                  </div>
                </div>
              </div>
              <div className="text-base font-normal leading-tight text-green-400">
                Amazing Deal
              </div>
            </div>
          </div>
        </div>
        <div
          className={cn(
            "flex w-[240px] cursor-pointer flex-col items-start justify-start gap-6 rounded-md bg-slate-950 px-6 py-4 shadow",
            selectedTab === "cash"
              ? "border-2 border-primary-gold border-opacity-50"
              : "border border-white border-opacity-25"
          )}
          onClick={() => setSelectedTab("cash")}
        >
          <div
            className={cn(
              "text-base font-normal leading-tight",
              selectedTab === "cash" ? "text-primary-gold" : "text-primary"
            )}
          >
            With Cash
          </div>
          <div className="flex flex-col items-start justify-start gap-2.5">
            <div className="text-base font-medium leading-tight text-primary">
              $1,250 USD/Night
            </div>
            <div className="inline-flex items-center justify-center gap-2 rounded-[103px] bg-amber-500 bg-opacity-20 px-4 py-1.5">
              <div className="inline-flex flex-col items-start justify-start gap-3">
                <div className="inline-flex items-center justify-start gap-2 rounded-[123px]">
                  <div className="relative h-2.5 w-3.5">
                    <div className="absolute left-[5.60px] top-0 h-2.5 w-[2.80px] bg-amber-500" />
                    <div className="absolute left-0 top-[1.25px] h-[8.75px] w-[2.80px] bg-amber-500" />
                    <div className="absolute left-[11.20px] top-[5px] h-[5px] w-[2.80px] bg-amber-500" />
                  </div>
                  <div className="text-base font-normal leading-tight text-amber-500">
                    5.5
                  </div>
                </div>
              </div>
              <div className="text-base font-normal leading-tight text-amber-500">
                Okay Deal
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={currentImageSrc}
        alt={currentImageSrc}
        className={cn("mx-auto h-fit w-[565px]", imageClassName)}
      />
    </div>
  );
};

export default PointsOrCashSection;
