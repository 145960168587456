"use client";

import React, { useState } from "react";
import { useMainLayout } from "@/app/context/mainLayoutProvider";
import { GoogleAuthModal } from "@/components/modal/googleAuthModal";
import WaitlistFormModal from "@/components/modal/waitlistFormModal";
import { Button } from "@/components/ui/button";

const CTASection: React.FC = () => {
  const [googleAuthModalOpen, setGoogleAuthModalOpen] = useState(false);
  const [showWaitlistFormModal, setShowWaitlistFormModal] = useState(false);

  const { isWebView } = useMainLayout();

  const onClick = () => {
    if (isWebView) {
      setShowWaitlistFormModal(true);
    } else {
      setGoogleAuthModalOpen(true);
    }
  };

  return (
    <div className="lg:mx-32">
      <GoogleAuthModal
        headerText="Create an account with Google"
        open={googleAuthModalOpen}
        onOpenChange={setGoogleAuthModalOpen}
      />
      <WaitlistFormModal
        open={showWaitlistFormModal}
        onOpenChange={(val) => {
          if (!val) setShowWaitlistFormModal(false);
        }}
      />
      <div className="mt-10 flex h-screen w-full flex-col items-center gap-12 bg-[url(/assets/landing-page/cta-background.svg)] bg-cover bg-center bg-no-repeat p-6 pt-[75px] lg:p-10 lg:pt-28">
        <div className="flex flex-col items-center justify-start gap-8">
          <div className="text-linear-gradient-default text-center text-5xl leading-[48px] md:text-8xl md:leading-[120px]">
            Join Gondola
          </div>
        </div>
        <div>
          <Button
            className="h-16 rounded-full bg-primary-gold !px-8"
            onClick={onClick}
            v2Effect
          >
            <img
              src="/assets/3-stars-gold-black.svg"
              alt="3-stars-gold-black"
              className="h-6 w-6"
            />
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CTASection;
