"use client";

import React from "react";
import testimonialData from "./TestimonialSection.data.json";
import TestimonialCard from "./TestimonialCard";

const TestimonialSection: React.FC = () => {
  const testimonialLen = testimonialData.length;

  const testimonialSet1 = testimonialData
    .slice(0, testimonialLen / 2)
    .map((data, index) => <TestimonialCard key={index} {...data} />);
  const testimonialSet2 = testimonialData
    .slice(testimonialLen / 2)
    .map((data, index) => <TestimonialCard key={index} {...data} />);

  return (
    <div className="flex flex-col items-center justify-center py-14">
      <div className="text-linear-gradient-default mx-5 mb-4 text-center text-[36px] leading-[1.2] md:text-[64px] lg:mx-28">
        What our members are saying about us
      </div>
      <div className="relative my-8 h-[160px] w-full overflow-hidden md:h-[210px]">
        <div className="scroll-x-left-infinite absolute left-0 flex space-x-8">
          {testimonialSet1}
          {testimonialSet1}
          {testimonialSet1}
        </div>
      </div>
      <div className="relative h-[160px] w-full overflow-hidden md:h-[210px]">
        <div className="scroll-x-right-infinite absolute left-0 flex space-x-8">
          {testimonialSet2}
          {testimonialSet2}
          {testimonialSet2}
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
