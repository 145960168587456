"use client";

import { mdBreakPoint, screenInnerWidth } from "@/lib/utils";
import React from "react";

const ClarityImageSection: React.FC = () => {
  const isLessThanMd = screenInnerWidth <= mdBreakPoint;

  const imgSrc = isLessThanMd
    ? "/assets/landing-page/clarity-mobile.svg"
    : "/assets/landing-page/clarity-desktop.svg";

  return (
    <img
      src={imgSrc}
      alt={imgSrc}
      className="mt-10 max-h-[650px] w-full md:mt-0"
    />
  );
};

export default ClarityImageSection;
