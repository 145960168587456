"use client";

import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";

const HotelExploreCardSkeleton: React.FC = () => {
  return (
    <div className="flex min-w-full max-w-[350px] animate-pulse flex-col gap-3 p-1 shadow md:min-w-[350px]">
      <div className="h-[300px] rounded-lg bg-gray-700" />
      <div className="flex h-[155px] w-full flex-col justify-between gap-1">
        <div className="flex flex-col gap-2">
          <div className="flex w-full items-start justify-between gap-2.5 self-stretch">
            <div className="h-6 w-[50%] rounded-md bg-gray-700" />
            <div className="h-6 w-[50%] rounded-md bg-gray-700" />
          </div>
          <div className="flex gap-1">
            <div className="mt-4 h-4 w-52 rounded-md bg-gray-700" />
          </div>
          <div className="flex flex-col items-start justify-center gap-2 self-stretch">
            <div className="h-3 w-36 rounded-md bg-gray-700" />
            <div className="h-3 w-full rounded-md bg-gray-700" />
          </div>
        </div>
        <div className="flex items-center justify-between gap-3">
          <div className="h-4 w-36 rounded-md bg-gray-700" />
          <div className="h-4 w-24 rounded-md bg-gray-700" />
        </div>
      </div>
    </div>
  );
};

export default HotelExploreCardSkeleton;
