"use client";

import { HotelSummary } from "@/generated/search.openapi";
import React, { useEffect } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HotelExploreCardSkeleton from "./HotelExploreCardSkeleton";
import {
  cn,
  lgBreakPoint,
  loyaltyAccountsColorMapping,
  mdBreakPoint,
  screenInnerWidth,
} from "@/lib/utils";
import { LoyaltyProgramEnum } from "@/generated/email_parser.openapi";
import { theme } from "@/themes";
import HotelCard from "../card/HotelCard";

type LProgram = {
  programName: LoyaltyProgramEnum;
  displayName: string;
  color: string;
};

const availableLoyaltyPrograms: LProgram[] = [
  {
    programName: LoyaltyProgramEnum.HiltonHonors,
    displayName: "Hilton",
    color: loyaltyAccountsColorMapping[LoyaltyProgramEnum.HiltonHonors],
  },
  {
    programName: LoyaltyProgramEnum.MarriottBonvoy,
    displayName: "Marriott",
    color: loyaltyAccountsColorMapping[LoyaltyProgramEnum.MarriottBonvoy],
  },
  {
    programName: LoyaltyProgramEnum.WorldOfHyatt,
    displayName: "Hyatt",
    color: loyaltyAccountsColorMapping[LoyaltyProgramEnum.WorldOfHyatt],
  },
  {
    programName: LoyaltyProgramEnum.IhgOneRewards,
    displayName: "IHG",
    color: loyaltyAccountsColorMapping[LoyaltyProgramEnum.IhgOneRewards],
  },
];

const defaultProgram = availableLoyaltyPrograms.find(
  (p) => p.programName === LoyaltyProgramEnum.HiltonHonors
) as LProgram;

const ExploreSection: React.FC = () => {
  const [hotels, setHotels] = React.useState<HotelSummary[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [sliderDivWidth, setSliderDivWidth] = React.useState<
    number | undefined
  >(undefined);
  const sliderDivRef = React.useRef<HTMLDivElement>(null);
  const [selectedLoyaltyProgram, setSelectedLoyaltyProgram] =
    React.useState<LProgram>(defaultProgram);

  useEffect(() => {
    setSliderDivWidth(
      (sliderDivRef.current?.getBoundingClientRect().width || 0) - 20
    );
  }, []);

  const fetchHotels = async () => {
    try {
      setLoading(true);
      const response = (await fetch("/api/public/hotel_explore").then((res) =>
        res.json()
      )) as HotelSummary[];
      setHotels(response);
      return response;
    } catch (error) {
      setHotels([]);
      return;
    } finally {
      setLoading(false);
    }
  };
  const settings: Settings = {
    className: "slider variable-width",
    variableWidth: true,
    dots: false,
    infinite: false,
    slidesToScroll: (sliderDivWidth ?? 0) < mdBreakPoint ? 1 : 3,
    slidesToShow: (sliderDivWidth ?? 0) < mdBreakPoint ? 1 : 3,
  };

  const isLessthenLg = screenInnerWidth < lgBreakPoint;

  if (isLessthenLg) {
    settings.className = "";
    settings.variableWidth = false;
  }

  useEffect(() => {
    fetchHotels();
  }, []);

  const filteredHotels = hotels?.length
    ? hotels.filter(
        (hotel) => hotel.loyaltyProgram === selectedLoyaltyProgram.programName
      )
    : [];
  return (
    <>
      {hotels.length && (
        <div className="flex flex-col items-center justify-center py-14">
          <div className="text-linear-gradient-default text-center text-[36px] leading-[1.2] md:text-[64px]">
            Explore The Best Points Deals
          </div>
          <div className="my-10 flex items-center justify-center gap-2">
            {availableLoyaltyPrograms.map((program) => (
              <div
                key={program.programName}
                onClick={() =>
                  !loading ? setSelectedLoyaltyProgram(program) : undefined
                }
                className={cn(
                  "rounded-lg border border-white/10 px-5 py-2 text-[10px] text-white md:px-10 md:text-base",
                  loading ? "animate-pulse cursor-wait" : "cursor-pointer"
                )}
                style={{
                  backgroundColor:
                    selectedLoyaltyProgram.programName === program.programName
                      ? program.color
                      : theme.extend.colors.card["background-dark"],
                }}
              >
                {program.displayName}
              </div>
            ))}
          </div>
          <div
            className="flex w-full px-2"
            ref={sliderDivRef}
            style={{ width: sliderDivWidth }}
          >
            <Slider {...settings} className="flex w-full">
              {loading
                ? Array.from({ length: 6 }).map((_, index) => (
                    <HotelExploreCardSkeleton key={index} />
                  ))
                : filteredHotels.map((hotel, index) => (
                    <HotelCard
                      key={index}
                      hotel={hotel}
                      className="mx-2 max-w-[300px] md:min-w-[350px] md:max-w-[350px]"
                      disableOnClick
                    />
                  ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
};

export default ExploreSection;
