import React from "react";

export type TestimonialCardProps = {
  name: string;
  review: string | string[];
  profilePic?: string;
};

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  name,
  profilePic,
  review,
}) => {
  return (
    <div className="flex h-[160px] w-[250px] flex-col items-start justify-start gap-1.5 rounded-2xl border border-primary-gold/30 bg-card-background-dark p-8 hover:border-primary-gold/100 md:h-[210px] md:w-[390px] md:gap-4">
      <div className="flex items-center justify-center gap-3">
        {profilePic && (
          <img
            className="h-10 w-10 rounded-full"
            src={profilePic}
            alt="profile-pic"
          />
        )}
        <div className="flex flex-col items-center justify-center self-stretch">
          <div className="line-clamp-1 text-xl leading-normal text-primary">
            {name}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start gap-3 self-stretch">
        <div className="line-clamp-3 self-stretch text-lg leading-[22.20px] text-light/50 md:line-clamp-4">
          {typeof review === "string"
            ? review
            : review.map((r, index) => (
                <div className="mb-2" key={index}>
                  {r}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
